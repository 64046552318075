import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/tmp/vultr-2-runner/work/kastle/kastle/packages/mdx/src/components/Layout/Default.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "freizeitaktivitäten-im-schloss"
    }}>{`Freizeitaktivitäten im Schloss`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4712e030725ca58ca34943dab647655d/eed55/LesLoisirsPiscine2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.104294478527606%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB80lEQVQY0wHoARf+ACY/Eyc7ES5BFSc3EiQyETREFjtQGyI1FCU4FCc7FB8xHDBHGzBIEiE4DjlRDE1oK0RZMjVFISxNWjFgbgAoPxMuRRU3VBg9WBk4TBcuQBArPhUZKREqQBY0SxYtRRQySg06ThcjNw8fLQdYbSRUZCk2RQ0zVE1DgJMAHCcPHSYQIi8VHS0NKjoTLjocLzgsJjImGCQJEB0EGigNHzAKHigMMDoQITASPlAaWGkyQE0eO1AkSVtaAA8RDg0XBAwdAjA5Km16a5aopKi/xafDynyRl0lcTREbDBEWCxYcDis2Dy88HDxZQVdrPktbNExhRGySnQAWJg0iNh9JWk6ImIpQWko2LCFpW1Odnpa1xMKuw8V3goMfIx0YJREwQC1ESixbZFJRZTNLXitOZVUsOT8Ac4hQk6eSq7utsb+xmbGjbIpvY2lVdHtllKKSmaSdq7Wuhop4ZmU3X1w/YGI/UlQnPU8OPVUaJC4VBAAAAHOFPbenjcm0ndG8psnGtKrU0XvZ5VTR4ljR3TjO6DTK4lDO41XM3lqfoYOXhIaGYFRaI4iFYCsoICEiGABddSKemm3UvKzTvKjewarjwqvnzbfc0sHP2MvE2My82Mu21sqo083Az8S2sJ6Wh2lWXixRVUYwLyMyNiY73qgr4ePXgAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4712e030725ca58ca34943dab647655d/f994e/LesLoisirsPiscine2.webp 163w", "/static/4712e030725ca58ca34943dab647655d/e93cc/LesLoisirsPiscine2.webp 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4712e030725ca58ca34943dab647655d/cf3f8/LesLoisirsPiscine2.png 163w", "/static/4712e030725ca58ca34943dab647655d/eed55/LesLoisirsPiscine2.png 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/4712e030725ca58ca34943dab647655d/eed55/LesLoisirsPiscine2.png",
              "alt": "Freizeit im Schloss von Morey",
              "title": "Freizeit im Schloss von Morey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Freizeit im Schloss von Morey`}</strong></p>
    <h2 {...{
      "id": "müssen-sie-sich-im-herzen-lothringens-erholen-und-entspannen"
    }}>{`Müssen Sie sich im Herzen Lothringens erholen und entspannen?`}</h2>
    <h3 {...{
      "id": "ein-moment-des-wohlbefindens-zwischen-nancy-und-metz"
    }}>{`Ein Moment des Wohlbefindens zwischen Nancy und Metz`}</h3>
    <p>{`Kommen Sie und finden Sie Trost im Château de Morey.
Profitieren Sie von unserem `}<em parentName="p">{`privaten SPA`}</em>{` mit 25 verstellbaren Massagedüsen in 38 Grad warmem Wasser mit ätherischen Ölen aus `}<strong parentName="p">{`Lothringen`}</strong>{`.
Entspannen Sie sich in einer Zen-Atmosphäre in diesem kleinen, warmen und ungewöhnlichen Kokon.`}</p>
    <h3 {...{
      "id": "für-eine-entspannende-massage-in-ihrem-zimmer"
    }}>{`Für eine entspannende Massage in Ihrem Zimmer:`}</h3>
    <p>{`Kontaktieren Sie Celine unter `}<a parentName="p" {...{
        "href": "tel:+33661347300",
        "target": "_blank",
        "rel": "noreferrer"
      }}>{`06 61 34 73 00`}</a></p>
    <h2 {...{
      "id": "die-aktivitäten-des-schlosses-von-morey"
    }}>{`Die Aktivitäten des Schlosses von Morey`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/26edbe93598e303a68bea7e6a402e2c0/eed55/LesLoisirsPiscine.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.104294478527606%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB80lEQVQY0wHoARf+ABgmECA0FS5GFic8EyxGEChEDSA4DRYnCDNPEkZgGkdeMU+Vt0if10Wk2UCr41twjW88OnA6IYErFHoxJwAYIw8UHwQLFwESHggfNA0XJwcUIAgeLxQZKws4UgtKWg1XiHpXruFsjrGdXXaga41iia5DcHRFXz4zV1QAjaiwcIiNUWRYHCYUBwwAFRwKFiAJHi8QL00/O11AOk4bXYJrd7rgjXeFnVlgqmVukYaVWn+CQk4fKDMSAKi+w5/AyZzCyoOorTtJSgQJBB4uHSk0IHqZpXOaqj5VQIKmsJO6y4/B1n6yyIK7zIq8z2yHjTpEIiw5GwB9Y06hnY6jnZWtraWwr6dYWEIwNRxeUz6BcVRRWUMvTBU+XTsoOh9BWEBMXz1CVS46Wz81SD4hJwsiLQsAVJCIXaajZaaqWqmxYa+2c7a5aKSbeqichqqTlKqMsK6EkIxqeXded3NWhodegoJdVl5AGBwRHSIKKzURAH7W5GLS4mDZ40Tc80Ha71XY6k7Y7GHW6IvY48be2fPVwqCJhXRjaGtaYWhkbWFZWkA2NhsbFSYrEDA3GgDlwavkx7Hhy7fjzLfky7Tmya/qyavtyrDwy7DtxqzkxKzTtZ/GqpaZg3VQSkUrMCYzNyojIhEyORc2Ox5vP8V+cif5BgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/26edbe93598e303a68bea7e6a402e2c0/f994e/LesLoisirsPiscine.webp 163w", "/static/26edbe93598e303a68bea7e6a402e2c0/e93cc/LesLoisirsPiscine.webp 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/26edbe93598e303a68bea7e6a402e2c0/cf3f8/LesLoisirsPiscine.png 163w", "/static/26edbe93598e303a68bea7e6a402e2c0/eed55/LesLoisirsPiscine.png 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/26edbe93598e303a68bea7e6a402e2c0/eed55/LesLoisirsPiscine.png",
              "alt": "Freizeit in Lothringen",
              "title": "Freizeit in Lothringen",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Freizeit in Lothringen`}</strong></p>
    <h3 {...{
      "id": "ein-entspannungskomplex"
    }}>{`Ein Entspannungskomplex`}</h3>
    <p>{`Sie werden ein Maximum an Wohlfühlraum nutzen können.
Unser 2001 erbautes unterirdisches Schwimmbad bietet Ihnen die Möglichkeit, sich zu sonnen, während Sie von einem Park mit Bäumen und dem Klang seiner Vögel umgeben sind. Ein Moment der Entspannung garantiert.
In der Nähe von `}<strong parentName="p">{`Nancy`}</strong>{` und `}<strong parentName="p">{`Metz`}</strong>{` können Sie sich während der sehr heißen Jahreszeiten `}<strong parentName="p">{`Lothringen`}</strong>{` erfrischen.`}</p>
    <h3 {...{
      "id": "genießen-sie-vielfältige-aktivitäten-in-unserem-haus-"
    }}>{`Genießen Sie vielfältige Aktivitäten in unserem Haus :`}</h3>
    <ul>
      <li parentName="ul">{`💦 Heilbad`}</li>
      <li parentName="ul">{`💆 Massage`}</li>
      <li parentName="ul">{`🏊 Pool`}</li>
      <li parentName="ul">{`🐴 Reiten`}</li>
      <li parentName="ul">{`🏐 Boulodrome`}</li>
      <li parentName="ul">{`🎱 Billard`}</li>
      <li parentName="ul">{`🎲 Spielraum`}</li>
      <li parentName="ul">{`🌳 Ein außergewöhnlicher bewaldeter Park`}</li>
      <li parentName="ul">{`🚴 Fahrräder zu Ihrer Verfügung`}</li>
      <li parentName="ul">{`🌐 Kostenloses Wifi`}</li>
      <li parentName="ul">{`📚 Bibliothek`}</li>
      <li parentName="ul">{`🐾 Tiere`}</li>
    </ul>
    <p>{`Unser bukolischer Park besteht aus einem riesigen Garten, der von einer mehr als 5 Meter hohen Mauer gestützt wird.
Mit Kastanienbäumen, libanesischen Zedern, Linden, Purpurbuchen, Eichen, Ahorn und einem Ententeich.
Ein perfekter Ort zum Spazierengehen und Entspannen.`}</p>
    <h2 {...{
      "id": "besuch-in-lothringen"
    }}>{`Besuch in Lothringen`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d85ff176205ab28d0b0779e0558eabf6/af98b/placestan.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAgMF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgAB/9oADAMBAAIQAxAAAAHm2zhFoULP/8QAGRABAQEAAwAAAAAAAAAAAAAAAAERAhIT/9oACAEBAAEFAvOpwdY1a1//xAAXEQADAQAAAAAAAAAAAAAAAAAAAQIT/9oACAEDAQE/AXCM5P/EABcRAAMBAAAAAAAAAAAAAAAAAAABAhP/2gAIAQIBAT8BVM0o/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGRABAQADAQAAAAAAAAAAAAAAAQARMVGh/9oACAEBAAE/IeGGQ3CG/IRKwjl//9oADAMBAAIAAwAAABBXL//EABYRAQEBAAAAAAAAAAAAAAAAAAABcf/aAAgBAwEBPxCls//EABYRAQEBAAAAAAAAAAAAAAAAAAABcf/aAAgBAgEBPxCNg//EABsQAQACAwEBAAAAAAAAAAAAAAEAESExcUFR/9oACAEBAAE/ECkUD6MQcQNrMYvxMqariXNk1XzEErLpP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d85ff176205ab28d0b0779e0558eabf6/f994e/placestan.webp 163w", "/static/d85ff176205ab28d0b0779e0558eabf6/bcd70/placestan.webp 325w", "/static/d85ff176205ab28d0b0779e0558eabf6/5d8d7/placestan.webp 650w", "/static/d85ff176205ab28d0b0779e0558eabf6/c4443/placestan.webp 975w", "/static/d85ff176205ab28d0b0779e0558eabf6/5cc32/placestan.webp 1300w", "/static/d85ff176205ab28d0b0779e0558eabf6/448ad/placestan.webp 1613w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d85ff176205ab28d0b0779e0558eabf6/367e5/placestan.jpg 163w", "/static/d85ff176205ab28d0b0779e0558eabf6/ab07c/placestan.jpg 325w", "/static/d85ff176205ab28d0b0779e0558eabf6/ebabe/placestan.jpg 650w", "/static/d85ff176205ab28d0b0779e0558eabf6/cdb69/placestan.jpg 975w", "/static/d85ff176205ab28d0b0779e0558eabf6/2616f/placestan.jpg 1300w", "/static/d85ff176205ab28d0b0779e0558eabf6/af98b/placestan.jpg 1613w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/d85ff176205ab28d0b0779e0558eabf6/ebabe/placestan.jpg",
              "alt": "place stanislas",
              "title": "place stanislas",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Place stanislas de Nancy`}</strong></p>
    <h3 {...{
      "id": "zwischen-nancy-und-metz-gelegen-könnte-sie-die-entdeckung-dieser-bezaubernden-städte-durchaus-in-erstaunen-versetzen"
    }}>{`Zwischen Nancy und Metz gelegen, könnte Sie die Entdeckung dieser bezaubernden Städte durchaus in Erstaunen versetzen.`}</h3>
    <p>{`20 Minuten von Nancy entfernt
Das Schloss von Morey, ist bekannt für seinen herrlichen Place Stanislas und für seine Altstadt, die Sie in das lothringische Mittelalter eintauchen lässt. Zahlreiche Museen sind in dieser geschichtsträchtigen Stadt zu finden.`}</p>
    <p>{`Metz, 30 Minuten vom Schloss Morey entfernt, ist berühmt für seine prachtvolle Kathedrale und die kaiserlich-germanische Architektur der Stadt.
Lassen Sie sich durch den Charme dieser Stadt führen, die mehrere Epochen überliefert.`}</p>
    <h3 {...{
      "id": "in-lothringen-gibt-es-viele-sehenswürdigkeiten-und-denkmäler-aus-allen-epochen-zu-sehen-es-ist-ein-privileg-eine-region-zu-haben-die-so-reich-an-geschichte-ist"
    }}>{`In Lothringen gibt es viele Sehenswürdigkeiten und Denkmäler aus allen Epochen zu sehen, es ist ein Privileg, eine Region zu haben, die so reich an Geschichte ist.`}</h3>
    <p>{`Pont à Mousson, 15 Minuten vom Château de Morey entfernt, ist ein kleines Städtchen voller Charme, mit vielen lokalen Restaurants, aber auch mit einigen außergewöhnlichen Sehenswürdigkeiten. Am bekanntesten ist die Prämonstratenserabtei, ein Monument des 18. Jahrhunderts, ein prächtiges Beispiel für die Klosterarchitektur Lothringens.`}</p>
    <p>{`Ein paar Kilometer weiter entfernt finden Sie :`}</p>
    <ul>
      <li parentName="ul">{`Verdun und seine historischen Stätten des Zweiten Weltkriegs`}</li>
      <li parentName="ul">{`Der Madinasee und das Monument des Butte de Montsec`}</li>
    </ul>
    <p>{`Für diejenigen, die sich für Château begeistern, haben wir Kollegen in der Region :`}</p>
    <ul>
      <li parentName="ul">{`Le Château de `}<a parentName="li" {...{
          "href": "https://www.chateau-malbrouck.com/",
          "target": "_blank",
          "rel": "noreferrer"
        }}>{`Malbrouck`}</a></li>
      <li parentName="ul">{`Le Château de `}<a parentName="li" {...{
          "href": "https://fr.wikipedia.org/wiki/Ch%C3%A2teau_de_Lun%C3%A9ville",
          "target": "_blank",
          "rel": "noreferrer"
        }}>{`Luneville`}</a></li>
      <li parentName="ul">{`Le Château de `}<a parentName="li" {...{
          "href": "https://www.tourisme-lorraine.fr/a-voir-a-faire/visites/sites-et-monuments/995000870-chateau-de-haroue-haroue",
          "target": "_blank",
          "rel": "noreferrer"
        }}>{`Haroue`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      